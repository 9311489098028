import { init as initFullStory, FullStory } from "@fullstory/browser";

/*

// Usage

const { $Fullstory } = useNuxtApp();

$FullStory('setProperties', {
  type: 'user',
  properties: {
    displayName: 'Daniel Falko',
    email: 'daniel.falko@example.com',
    pricing_plan: 'free',
    popup_help: true,
    total_spent: 14.50,
  },
});

*/

export default defineNuxtPlugin({
  name: "fullstory",
  parallel: true, //non-blocking
  async setup(nuxtApp) {
    const debug = getDebugValue();

    initFullStory({ orgId: "157JFB", debug });
    nuxtApp.provide("Fullstory", FullStory);
  },
});

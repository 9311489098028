// SSR
// const { data } = await useAsyncData(
//   `${props.postType}-article-list`, <-- This is the key for the cache
//   () =>
//     useAlgoliaSearch({
//       query: searchQuery.value, <-- This is the search query
//       requestOptions: { <-- This is the search options
//         hitsPerPage: 1000,
//         filters: `category:'${props.postType}'`,
//         clickAnalytics: true,
//         userToken: gaUserToken.value,
//       },
//     }),
//   {
//     watch: [searchQuery], <-- This is the watch array if you want to re-fetch the data
//   },
// );

// Client Side
//     useAlgoliaSearch({
//       query: searchQuery.value, <-- This is the search query
//       requestOptions: { <-- This is the search options
//         hitsPerPage: 1000,
//         filters: `category:'${props.postType}'`,
//         clickAnalytics: true,
//         userToken: gaUserToken.value,
//       },
//     })

import { algoliasearch } from "algoliasearch";
import { createFetchRequester } from "@algolia/requester-fetch";
import type { SearchParams, SearchResponse } from "@algolia/client-search";
import type { Hit } from "@/types/algolia";

export const useAlgoliaSearch = async (
  searchParams?: { query: string; requestOptions: SearchParams },
  indexName?: string,
): Promise<SearchResponse<Hit>> => {
  const config = useRuntimeConfig();
  const index = indexName || config.public.algolia.globalIndex;
  const applicationId = config.public.algolia.applicationId;
  const apiKey = config.public.algolia.apiKey;

  if (!index) {
    throw new Error(
      "`[algolia]` Cannot search in Algolia without `globalIndex` or `indexName` passed as a parameter",
    );
  }

  const client = algoliasearch(applicationId, apiKey, {
    requester: createFetchRequester(),
  });

  const response = await client.searchSingleIndex({
    indexName: index,
    searchParams: {
      query: searchParams?.query,
      ...searchParams?.requestOptions,
    },
  });

  return response as SearchResponse<Hit>;
};

<template>
  <NuxtLayout :error="error">
    <section class="container flex pt-12 md:pt-16">
      <div v-if="error.statusCode === 404">
        <h1 class="normal-case">404 - Page not found</h1>
        <p>
          Oops, looks like that page doesn't exist. Maybe there is an something
          below that is what you are looking for?
        </p>

        <ul
          v-if="data && data.hits.length > 0"
          class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4"
        >
          <li
            v-for="hit in data.hits.slice(0, 4)"
            :key="hit.objectID"
            class="flex flex-col"
          >
            <NuxtLink
              class="flex h-full cursor-pointer flex-col rounded-lg border border-grey-300 bg-white p-4 text-left text-grey-900 no-underline hover:bg-green-100"
              :to="hit.url"
              :external="true"
            >
              <p class="mb-1 font-bold">{{ hit.title }}</p>
              <p class="grow !pb-0 text-base">
                {{
                  hit.description
                    ? `${hit.description.substring(0, 80)}...`
                    : "Read more..."
                }}
              </p>
            </NuxtLink>
          </li>
        </ul>
        <p v-else class="italic !text-grey-900">
          Unfortunately there is no search results for
          <span class="font-bold">{{ searchString }}</span>
        </p>

        <h2 class="mt-16 !text-3xl normal-case !text-grey-900">
          Alternatively, you can try one of the links below:
        </h2>
        <ul class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          <li
            v-for="(el, idx) in staticLinks[isActive]"
            :key="idx"
            class="flex flex-col"
          >
            <NuxtLink
              class="flex h-full cursor-pointer flex-col rounded-lg border border-grey-300 bg-white p-4 text-left text-grey-900 no-underline hover:bg-green-100"
              :to="el.link"
            >
              <p class="mb-1 font-bold">{{ el.name }}</p>
              <p class="grow text-base">{{ el.desc }}</p>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div v-else-if="error.statusCode === 410">
        <h1 class="normal-case">410 - Page Permanently Gone</h1>
        <p>
          Sorry, this page has been permanently removed and will not return.
          Please remove all bookmarks to this page.
        </p>
        <BtnPrimary
          label="Go home"
          size="large"
          arrow="right"
          url="https://www.aussiebroadband.com.au/"
        />
      </div>
    </section>
  </NuxtLayout>
</template>
<script setup lang="ts">
const props = defineProps<{
  error: {
    url: string;
    statusCode: number;
    statusMessage: string;
    message: string;
    description: string;
  };
}>();

const route = useRoute();
const urlData = route.fullPath
  .split("?")[0]
  .split("/")
  .filter((str) => str !== "");

const searchString =
  urlData.length >= 1 ? urlData[urlData.length - 1].split("-").join(" ") : "";

const { data } = await useAsyncData("error-search-results", () =>
  useAlgoliaSearch({
    query: searchString,
    requestOptions: {
      hitsPerPage: 10,
    },
  }),
);

const isActive = computed(() => {
  const pathArr = route.fullPath.split("/");
  return pathArr.includes("business")
    ? "business"
    : pathArr.includes("enterprise")
      ? "enterprise"
      : pathArr.includes("wholesale")
        ? "wholesale"
        : "personal";
});

const staticLinks = {
  personal: [
    {
      name: "nbn® Internet Plans",
      desc: "Unlimited nbn® plans with Australia's most trusted telco.",
      link: "https://www.aussiebroadband.com.au/nbn-plans/",
    },
    {
      name: "Mobile Sim Plans",
      desc: "Awe-sim 5G mobile plans.",
      link: "/mobile/sim-only-plans/",
    },
    {
      name: "Mobile Broadband",
      desc: "Easy to get Mobile Broadband for your tablets and other mobile devices for when you're out and about.",
      link: "/mobile/data-only-sim/",
    },
  ],
  business: [
    {
      name: "Business nbn®",
      desc: "Fast, reliable nbn®. Customisable to suit your business.",
      link: "https://www.aussiebroadband.com.au/business/internet/nbn-plans/",
    },
    {
      name: "Business Mobile Sim Plans",
      desc: "Share data with your team and easily manage your mobile services on the MyAussie® app.",
      link: "/business/mobile/sim-plans/",
    },
    {
      name: "nbn® $0 FTTP Upgrade",
      desc: "Find out your business is eligible for a free fibre upgrade",
      link: "https://www.aussiebroadband.com.au/business/internet/nbn-fttp-upgrade/",
    },
    {
      name: "Business Phone Systems",
      desc: "Connect to the new standard in phone technology",
      link: "https://www.aussiebroadband.com.au/business/voice/hosted-pbx/",
    },
  ],
  enterprise: [
    {
      name: "Enterprise Internet",
      desc: "Solutions to get your Enterprise connected, from nbn® to Enterprise Ethernet.",
      link: "/enterprise/internet/",
    },
    {
      name: "Managed Network Services",
      desc: "SD-WAN, Hosted Firewall, Private IP and more.",
      link: "/enterprise/network/",
    },
    {
      name: "Managed Cloud Services",
      desc: "Move your data out of your premises and into the cloud.",
      link: "/enterprise/cloud/",
    },
    {
      name: "Managed Security Services",
      desc: "Don't be the next headline - put your security in trusted hands.",
      link: "/enterprise/security/",
    },
  ],
  wholesale: [
    {
      name: "Wholesale Carrier",
      desc: "Carrier network and voice services from a wholesale partner ready to scale with your business.",
      link: "/wholesale/carrier/",
    },
    {
      name: "Managed Service Provider Partner",
      desc: "Offer more value with our fast and reliable network, and a partnership ready to grow.",
      link: "/wholesale/msp/",
    },
    {
      name: "White Label",
      desc: "Offer our award-winning service under your own brand and improve your customer loyalty.",
      link: "/wholesale/white-label/",
    },
    {
      name: "Partner Program",
      desc: "Give your customers access to the best residential nbn® and mobile services we have to offer.",
      link: "/wholesale/partner-program/",
    },
  ],
};

const title = ref(`${searchString} | Aussie Broadband`);
useHead({ title: title.value });
</script>
